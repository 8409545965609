import { Thing } from "./Thing";

import { Box } from "@chakra-ui/react";
import { WebEntry } from "@dreamboard/WebEntry";
import { useColour } from "../contexts/ColourContext";
import { useEntries } from "../contexts/EntryContext";
import { getContrastingColour } from "../helpers/getContrastingColour";
import { ExternalLink } from "./ExternalLink";
import "./Thing.css";

export interface WebThingProps {
    entry: WebEntry;
}

export const WebThing = ({ entry }: WebThingProps) => {
    const {
        colour: { colour },
    } = useColour();
    const { editEntry, deleteEntry } = useEntries();

    const { payload } = entry;

    return (
        <Thing
            dataTestId="web-thing"
            entry={entry}
            onUpdate={editEntry}
            onDelete={deleteEntry}
            header={
                !!payload.url && (
                    <ExternalLink
                        colour={getContrastingColour(colour)}
                        url={payload.url!}
                    />
                )
            }
        >
            <Box width="100%" background={"white"}>
                <img
                    src={payload.image}
                    alt={payload["image:alt"]}
                    style={{ width: "100%", pointerEvents: "none" }}
                />
            </Box>
        </Thing>
    );
};
