import { VStack } from "@chakra-ui/react";

import { useAuth } from "../contexts/AuthContext";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { EditableDreamBoard } from "../components/EditableDreamBoard";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ReadOnlyDreamBoard } from "../components/ReadOnlyDreamBoard";
import { SignInForm } from "../components/SignInForm";
import "./App.css";

export const App = () => {
    const { isLoading, isLoggedIn } = useAuth();

    const content = isLoading ? (
        <LoadingSpinner />
    ) : !isLoggedIn ? (
        <SignInForm />
    ) : (
        <EditableDreamBoard />
    );

    const router = (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={content} />
                <Route path="/b/:id" element={<ReadOnlyDreamBoard />} />
            </Routes>
        </BrowserRouter>
    );

    return <VStack className="App">{router}</VStack>;
};
