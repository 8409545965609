import { ImageEntry } from "@dreamboard/ImageEntry";
import { FaFilePdf } from "react-icons/fa";
import { useEntries } from "../contexts/EntryContext";
import { Thing } from "./Thing";

import { Icon, Link } from "@chakra-ui/react";
import "./Thing.css";

export interface PdfThingProps {
    entry: ImageEntry;
}

export const PdfThing = ({ entry }: PdfThingProps) => {
    const { editEntry, deleteEntry } = useEntries();

    return (
        <Thing
            dataTestId="pdf-thing"
            entry={entry}
            onUpdate={editEntry}
            onDelete={deleteEntry}
            hideCard={true}
        >
            <Link onDoubleClick={() => window.open(entry.payload, "_blank")}>
                <Icon boxSize={100}>
                    <FaFilePdf />
                </Icon>
            </Link>
        </Thing>
    );
};
