import {
    HTMLProps,
    PropsWithChildren,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";

import { Box, Flex, Spacer, VStack } from "@chakra-ui/react";
import { useColour } from "../contexts/ColourContext";
import { useEntries } from "../contexts/EntryContext";

import { AnyEntry } from "@dreamboard/AnyEntry";
import { Entry } from "@dreamboard/Entry";
import { useBoard } from "../contexts/BoardContext";
import { getContrastingColour } from "../helpers/getContrastingColour";
import { CloseLink } from "./CloseLink";
import "./Thing.css";

export interface ThingProps<T> extends HTMLProps<HTMLDivElement> {
    dataTestId: string;
    header?: ReactNode;
    entry: Entry<T>;
    hideCard?: boolean;
    zIndex?: number;
    onDoubleClick?: () => void;
    onUpdate: (e: Entry<T>) => void;
    onDelete: (e: Entry<T>) => void;
}

export function Thing<T>({
    className,
    zIndex,
    width,
    dataTestId,
    header,
    children,
    entry,
    hideCard,
    onDoubleClick,
    onUpdate,
    onDelete,
}: PropsWithChildren<ThingProps<T>>) {
    const { isSelected, x, y } = entry;

    const [hasDragged, setHasDragged] = useState(false);

    const { isReadOnly } = useBoard();
    const { colour } = useColour();
    const { entries, editEntry } = useEntries();

    useEffect(() => {
        if (isSelected)
            onUpdate({
                ...entry,
                colour: colour.colour,
            });
    }, [colour]); // eslint-disable-line react-hooks/exhaustive-deps

    const onStop = (_: DraggableEvent, data: DraggableData) => {
        onUpdate({ ...entry, x: data.x, y: data.y });
    };

    const onDrag = () => {
        setHasDragged(true);
    };

    const deselectAllEntries = () => {
        entries
            .filter((e: AnyEntry) => e.id !== entry.id)
            .forEach((e: AnyEntry) => editEntry({ ...e, isSelected: false }));
    };

    const nodeRef = useRef(null);

    const thing = (
        <VStack
            className={
                className ||
                `Thing ${isSelected ? "selected" : ""} ${
                    hideCard ? "hidden-card" : ""
                }`
            }
            style={{ zIndex, width: width ?? "250px" }}
            backgroundColor={entry.colour}
            ref={nodeRef}
            onDoubleClick={() => {
                if (isReadOnly || !onDoubleClick) return;
                onDoubleClick();
            }}
            onClick={() => {
                if (isReadOnly) return;

                deselectAllEntries();

                if (!hasDragged || !isSelected) {
                    onUpdate({
                        ...entry,
                        isSelected: !isSelected,
                    });
                } else {
                    setHasDragged(false);
                }
            }}
            onMouseUp={() => setHasDragged(false)}
            data-testid={dataTestId}
            onMouseDown={(e) => e.preventDefault()}
            tabIndex={0}
            onKeyDown={(e) => {
                if (isSelected && e.key === "Backspace") onDelete(entry);
            }}
        >
            {(!hideCard && (
                <div style={{ width: "100%" }}>
                    <Flex m="8px" height="8px">
                        {!isReadOnly && header}

                        <Spacer />

                        {!isReadOnly && (
                            <CloseLink
                                colour={getContrastingColour(entry.colour)}
                                entry={entry}
                            />
                        )}
                    </Flex>

                    <Box width="100%" backgroundColor="white">
                        {children}
                    </Box>
                </div>
            )) || <div style={{ height: "100%" }}>{children}</div>}
        </VStack>
    );

    return (
        <Draggable
            bounds="parent"
            grid={[25, 25]}
            onStop={onStop}
            onDrag={onDrag}
            nodeRef={nodeRef}
            position={{ x, y }}
            disabled={isReadOnly}
        >
            {thing}
        </Draggable>
    );
}
