import { IconButton } from "@chakra-ui/react";

import { JSXElementConstructor, ReactElement } from "react";
import "./ToolbarButton.css";

export interface ToolbarButtonProps {
    label: string;
    isActive?: boolean;
    icon: ReactElement<any, string | JSXElementConstructor<any>>;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ToolbarButton = ({
    label,
    icon,
    isActive,
    onClick,
}: ToolbarButtonProps) => {
    const active = isActive === undefined ? true : isActive;

    return (
        <IconButton
            className={`ToolbarButton ${active ? "" : "inactive"}`}
            isRound
            aria-label={label}
            icon={icon}
            onClick={onClick}
        />
    );
};
