import { Canvas } from "fabric";
import {
    createContext,
    FC,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";

export interface CanvasContextType {
    canvas?: Canvas;
    setCanvas: (canvas: Canvas) => void;
}

export const CanvasContext = createContext<CanvasContextType | undefined>(
    undefined
);

interface CanvasProviderProps {
    children: ReactNode;
}

export const CanvasProvider: FC<CanvasProviderProps> = ({ children }) => {
    const [canvas, setCanvas] = useState<Canvas>();

    useEffect(() => {
        async function loadCanvas() {
            const str = localStorage.getItem("canvas");

            if (!str || str === "undefined" || !canvas) return;

            const obj = JSON.parse(str);
            const c = await canvas.loadFromJSON(obj);
            c.renderAll();

            setCanvas(c);
        }

        loadCanvas();
    }, [setCanvas, canvas]);

    return (
        <CanvasContext.Provider value={{ canvas, setCanvas }}>
            {children}
        </CanvasContext.Provider>
    );
};

export const useCanvas = (): CanvasContextType => {
    const context = useContext(CanvasContext);
    if (!context) {
        throw new Error("useCanvas must be used within a CanvasProvider");
    }

    return context;
};

export function saveCanvasToLocalStorage(canvas: Canvas) {
    const obj = canvas.toJSON();
    const str = JSON.stringify(obj);

    localStorage.setItem("canvas", JSON.stringify(str));
    pushCanvasHistory(str);

    return str;
}

export function pushCanvasHistory(canvasStr: string) {
    const canvasHistory = JSON.parse(
        localStorage.getItem("canvasHistory") ?? "[]"
    );

    canvasHistory.push(canvasStr);
    localStorage.setItem("canvasHistory", JSON.stringify(canvasHistory));
}

export function popCanvasHistory(): string {
    const canvasHistory = JSON.parse(
        localStorage.getItem("canvasHistory") ?? "[]"
    );

    const canvas = canvasHistory.pop();
    localStorage.setItem("canvasHistory", JSON.stringify(canvasHistory));

    return canvas;
}
