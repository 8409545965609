import { YouTubeEntry } from "@dreamboard/YouTubeEntry";
import { useEntries } from "../contexts/EntryContext";
import { Thing } from "./Thing";

import YouTube from "react-youtube";
import { getContrastingColour } from "../helpers/getContrastingColour";
import { ExternalLink } from "./ExternalLink";

import "./Thing.css";

export interface YouTubeThingProps {
    entry: YouTubeEntry;
}

export const YouTubeThing = ({ entry }: YouTubeThingProps) => {
    const { editEntry, deleteEntry } = useEntries();

    const videoId = extractVideoId(entry.payload ?? "") ?? "";
    const opts = { height: "100%", width: "100%" };

    return (
        <Thing
            dataTestId="youtube-thing"
            entry={entry}
            onUpdate={editEntry}
            onDelete={deleteEntry}
            header={
                <ExternalLink
                    url={entry.payload}
                    colour={getContrastingColour(entry.colour)}
                />
            }
        >
            <YouTube videoId={videoId} opts={opts} />
        </Thing>
    );
};

function extractVideoId(url: string) {
    const pattern =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(pattern);

    if (match && match[1]) {
        return match[1];
    } else {
        return null;
    }
}
