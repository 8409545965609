import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";

export interface SimpleTextEntryCreateModalProps {
    maxLength?: number;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (value: string) => void;
}

export const SimpleTextEntryCreateModal = ({
    maxLength,
    isOpen,
    onClose,
    onSubmit,
}: SimpleTextEntryCreateModalProps) => {
    const [text, setText] = useState("");

    const handleModalClose = () => {
        onSubmit(text);
        setText("");
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <ModalCloseButton />
                </ModalHeader>

                <ModalBody>
                    <Input
                        maxLength={maxLength ?? 50}
                        autoFocus={true}
                        onChange={(content) =>
                            setText(content.currentTarget.value)
                        }
                    />
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleModalClose}>
                        Save
                    </Button>

                    <Button variant="ghost" ml={3} onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
