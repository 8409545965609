import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useState,
} from "react";

interface UndoContextType {
    push: (fn: () => void) => void;
    pop: () => void;
    stack: (() => void)[];
}

export const UndoContext = createContext<UndoContextType | undefined>(
    undefined
);

export const UndoProvider = ({ children }: PropsWithChildren) => {
    const [stack, setStack] = useState<(() => void)[]>([]);

    const push = useCallback((fn: () => void) => {
        setStack((prevStack) => [...prevStack, fn]);
    }, []);

    const pop = useCallback(() => {
        setStack((prevStack) => {
            if (prevStack.length === 0) return prevStack;

            const newStack = [...prevStack];
            const fn = newStack.pop();

            setTimeout(() => {
                fn && fn();
            }, 0);

            return newStack;
        });
    }, []);

    return (
        <UndoContext.Provider value={{ push, pop, stack }}>
            {children}
        </UndoContext.Provider>
    );
};

export const useUndo = (): UndoContextType => {
    const context = useContext(UndoContext);
    if (!context) {
        throw new Error("useUndo must be used within an UndoProvider");
    }

    return context;
};
