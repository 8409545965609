import { CopyIcon } from "@chakra-ui/icons";
import {
    Button,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import { useBoard } from "../contexts/BoardContext";

export interface ShareCodeModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const ShareCodeModal = ({ isOpen, onClose }: ShareCodeModalProps) => {
    const { shareCode } = useBoard();

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <ModalCloseButton />
                </ModalHeader>

                <ModalBody>
                    <InputGroup>
                        <Input defaultValue={shareCode} />
                        <InputRightElement>
                            <IconButton
                                onClick={() =>
                                    navigator.clipboard.writeText(shareCode)
                                }
                                size="sm"
                                variant="outline"
                                aria-label="Copy share code"
                            >
                                <CopyIcon />
                            </IconButton>
                        </InputRightElement>
                    </InputGroup>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" onClick={onClose}>
                        Cool!
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
