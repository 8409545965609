import { useEffect, useState } from "react";
import { DreamBoard } from "../app/DreamBoard";
import { BottomToolbar } from "../toolbars/BottomToolbar";
import { TopToolbar } from "../toolbars/TopToolbar";

export const EditableDreamBoard = () => {
    const [scale, setScale] = useState(0.8);

    useEffect(() => {
        const viewportHeight = window.innerHeight;
        const elementHeight = 1300;
        const initialScale = viewportHeight / elementHeight;

        setScale(initialScale);
    }, []);

    return (
        <>
            <TopToolbar />
            <DreamBoard scale={scale} />
            <BottomToolbar defaultValue={scale} onChange={(v) => setScale(v)} />
        </>
    );
};
