import { Box, Portal, VStack } from "@chakra-ui/react";
import { useColour } from "../contexts/ColourContext";

import { ReactComponent as PaletteIcon } from "../img/SVG/PaletteIcon.svg";
import "./ColourPalette.css";
import { ToolbarButton } from "./ToolbarButton";

export const ColourPalette = () => {
    const { setColour, showPalette, setShowPalette } = useColour();

    const colours = [
        "#FFFFFF",
        "#130B1E",
        "#FFD550",
        "#50FF50",
        "#FF296B",
        "#FFC0ED",
        "#1EAAF7",
    ];

    return (
        <>
            <ToolbarButton
                isActive={!showPalette}
                label="Choose colour"
                icon={<PaletteIcon height="50%" />}
                onClick={() => setShowPalette(!showPalette)}
            />

            <Portal>
                <VStack
                    className="ColourPalette"
                    style={{ display: !showPalette ? "none" : "" }}
                >
                    {colours.map((colour, index) => (
                        <ToolbarButton
                            key={index}
                            onClick={() => setColour(colour)}
                            label={colour}
                            icon={
                                <Box
                                    width="25px"
                                    height="25px"
                                    bg={colour}
                                    borderRadius="md"
                                    boxShadow="md"
                                    transition="all 0.3s"
                                />
                            }
                        ></ToolbarButton>
                    ))}
                </VStack>
            </Portal>
        </>
    );
};
