import { useDisclosure } from "@chakra-ui/react";
import { useEntries } from "../contexts/EntryContext";
import { TextEntryUpdateModal } from "../modals/TextEntryUpdateModal";
import { Thing } from "./Thing";

import { TextEntry } from "@dreamboard/TextEntry";

import "./Thing.css";

export interface TextThingProps {
    entry: TextEntry;
}

export const TextThing = ({ entry }: TextThingProps) => {
    const { editEntry, deleteEntry } = useEntries();
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Thing
                entry={entry}
                dataTestId="text-thing"
                onDoubleClick={onOpen}
                onUpdate={editEntry}
                onDelete={deleteEntry}
            >
                <div dangerouslySetInnerHTML={{ __html: entry.payload }} />
            </Thing>

            <TextEntryUpdateModal
                entry={entry}
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={(t) => editEntry(t)}
            />
        </>
    );
};
