import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
} from "@chakra-ui/react";
import { TextEntry } from "@dreamboard/TextEntry";
import { useState } from "react";

export interface SimpleTextEntryUpdateModalProps {
    maxLength?: number;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (updatedEntry: TextEntry) => void;
    entry: TextEntry;
}

export const SimpleTextEntryUpdateModal = ({
    maxLength,
    isOpen,
    onClose,
    onSubmit,
    entry,
}: SimpleTextEntryUpdateModalProps) => {
    const [text, setText] = useState(entry.payload);

    const handleModalClose = () => {
        onSubmit({ ...entry, payload: text });
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />

                <ModalBody>
                    <ModalBody>
                        <Input
                            maxLength={maxLength ?? 50}
                            value={text}
                            autoFocus={true}
                            onChange={(content) =>
                                setText(content.currentTarget.value)
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleModalClose();
                            }}
                        />
                    </ModalBody>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleModalClose}>
                        Save
                    </Button>

                    <Button variant="ghost" ml={3} onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
