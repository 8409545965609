import { Image, useDisclosure } from "@chakra-ui/react";
import { StickyEntry } from "@dreamboard/StickyEntry";
import { useEntries } from "../contexts/EntryContext";
import { Thing } from "./Thing";

import Sticky from "../img/SVG/Sticky.svg";

import "@fontsource/walter-turncoat";
import { SimpleTextEntryUpdateModal } from "../modals/SimpleTextEntryUpdateModal";

import "./StickyThing.css";

export interface StickyThingProps {
    entry: StickyEntry;
}

export const StickyThing = ({ entry }: StickyThingProps) => {
    const { editEntry, deleteEntry } = useEntries();
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Thing
                zIndex={1000}
                entry={entry}
                dataTestId="sticky-thing"
                onDoubleClick={onOpen}
                onUpdate={editEntry}
                onDelete={deleteEntry}
                hideCard={true}
                width="175px"
            >
                <Image
                    className={`${entry.isSelected ? "selected" : ""}`}
                    style={{ pointerEvents: "none" }}
                    onClick={(e) => e.preventDefault}
                    src={Sticky}
                />

                <div
                    className="StickyThing"
                    style={{
                        position: "fixed",
                        top: 20,
                        left: -10,
                        fontFamily: "Walter Turncoat, cursive",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                    }}
                    dangerouslySetInnerHTML={{ __html: entry.payload }}
                />
            </Thing>

            <SimpleTextEntryUpdateModal
                entry={entry}
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={(t) => editEntry(t)}
            />
        </>
    );
};
