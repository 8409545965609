import { CloseIcon } from "@chakra-ui/icons";
import { Avatar, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useAuth } from "../contexts/AuthContext";
import { ReactComponent as SettingsIcon } from "../img/SVG/Settings.svg";

export const SettingsButton = () => {
    const { user } = useAuth();

    const clearIndexedDB = async () => {
        const dbs = await indexedDB.databases();
        dbs.forEach(({ name }) => {
            if (!name) return;
            indexedDB.deleteDatabase(name);
        });

        window.location.reload();
    };

    return (
        <Menu>
            <MenuButton
                boxSize="20px"
                position="absolute"
                top="20px"
                right="20px"
                cursor="pointer"
            >
                <SettingsIcon />
            </MenuButton>

            <MenuList>
                <MenuItem icon={<Avatar src={user?.photoURL ?? ""} />}>
                    {user?.displayName}
                </MenuItem>
                <MenuItem icon={<CloseIcon />} onClick={clearIndexedDB}>
                    Sign Out
                </MenuItem>
            </MenuList>
        </Menu>
    );
};
