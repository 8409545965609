import { ImageEntry } from "@dreamboard/ImageEntry";
import { useEntries } from "../contexts/EntryContext";
import { Thing } from "./Thing";

import "./Thing.css";

export interface ImageThingProps {
    entry: ImageEntry;
}

export const ImageThing = ({ entry }: ImageThingProps) => {
    const { editEntry, deleteEntry } = useEntries();

    return (
        <Thing
            dataTestId="image-thing"
            entry={entry}
            onUpdate={editEntry}
            onDelete={deleteEntry}
            hideCard={true}
        >
            <img
                style={{ width: "100%", pointerEvents: "none" }}
                src={entry.payload}
                alt={entry.payload}
            />
        </Thing>
    );
};
