import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import theme from "../app/Theme";
import { AuthProvider } from "../contexts/AuthContext";
import { BoardProvider } from "../contexts/BoardContext";
import { CanvasProvider } from "../contexts/CanvasContext";
import { ColourProvider } from "../contexts/ColourContext";
import { EntryProvider } from "../contexts/EntryContext";
import { UndoProvider } from "../contexts/UndoContext";

export const AppProvider = ({ children }: React.PropsWithChildren) => {
    return (
        <AuthProvider>
            <BoardProvider>
                <ChakraProvider theme={theme}>
                    <UndoProvider>
                        <CanvasProvider>
                            <ColourProvider>
                                <EntryProvider>{children}</EntryProvider>
                            </ColourProvider>
                        </CanvasProvider>
                    </UndoProvider>
                </ChakraProvider>
            </BoardProvider>
        </AuthProvider>
    );
};
