import { Box, HStack, Image, useDisclosure } from "@chakra-ui/react";

import { ColourPalette } from "../components/ColourPalette";
import { ToolbarButton } from "../components/ToolbarButton";
import { useEntries } from "../contexts/EntryContext";
import { useUndo } from "../contexts/UndoContext";
import logo from "../img/SVG/Logo.svg";
import postit from "../img/SVG/PostIt.svg";
import print from "../img/SVG/Print.svg";
import text from "../img/SVG/Text.svg";
import icon from "../img/SVG/UndoIcon.svg";
import { TextEntryCreateModal } from "../modals/TextEntryCreateModal";

import { getDatabase, ref, set } from "firebase/database";
import { FaShareAlt } from "react-icons/fa";
import {
    adjectives,
    animals,
    colors,
    uniqueNamesGenerator,
} from "unique-names-generator";
import { SettingsButton } from "../components/SettingsButton";
import { useAuth } from "../contexts/AuthContext";
import { useBoard } from "../contexts/BoardContext";
import { ShareCodeModal } from "../modals/ShareCodeModal";
import { SimpleTextEntryCreateModal } from "../modals/SimpleTextEntryCreateModal";

import "./TopToolbar.css";

export const TopToolbar = () => {
    const { pop, stack } = useUndo();
    const { addEntry } = useEntries();
    const { setShareCode, shareCode } = useBoard();
    const { user } = useAuth();

    const {
        isOpen: isTextModalOpen,
        onOpen: onTextModalOpen,
        onClose: onTextModalClose,
    } = useDisclosure();

    const {
        isOpen: isStickyModalOpen,
        onOpen: onStickyModalOpen,
        onClose: onStickyModalClose,
    } = useDisclosure();

    const {
        isOpen: isShareModalOpen,
        onOpen: onSharedModalOpen,
        onClose: onShareModalClose,
    } = useDisclosure();

    async function onShareBoardClick(_: any): Promise<void> {
        if (shareCode) {
            onSharedModalOpen();
            return;
        }

        const code = uniqueNamesGenerator({
            dictionaries: [adjectives, colors, animals],
            length: 3,
            separator: "-",
            style: "lowerCase",
        });

        const db = getDatabase();
        const dbRef = ref(db, `shares/${code}`);

        await set(dbRef, user?.uid);

        setShareCode(code);
        onSharedModalOpen();
    }

    return (
        <>
            <Image
                src={logo}
                boxSize="60px"
                position="absolute"
                top="0px"
                left="20px"
            />

            <SettingsButton />

            <div className="TopToolbar">
                <Box
                    borderWidth="0px"
                    borderRadius="20px"
                    backgroundColor="transparent"
                    mx="auto"
                    display="inline-block"
                    px={10}
                    py={0}
                    mt={2}
                >
                    <HStack spacing={4} alignItems="center">
                        {/* <ToolbarButton
                            label="Selection"
                            icon={<Image src={mouse} height={4} />}
                            onClick={() =>
                                setPenConfig({
                                    penMode: "select",
                                })
                            }
                        /> */}

                        <ToolbarButton
                            label="Write text"
                            icon={<Image src={text} height={4} />}
                            onClick={onTextModalOpen}
                        />

                        <ToolbarButton
                            label="Add sticky note"
                            icon={<Image src={postit} height={5} ml={0.4} />}
                            onClick={onStickyModalOpen}
                        />

                        {/* <ToolbarButton
                            label="Pen"
                            icon={<FaPen />}
                            onClick={() =>
                                setPenConfig({
                                    penMode: "draw",
                                })
                            }
                        /> */}

                        <ColourPalette />
                        <ToolbarButton
                            onClick={window.print}
                            label="Print"
                            icon={<Image src={print} height={5} />}
                        />
                        <ToolbarButton
                            isActive={stack.length > 0}
                            label={"Undo"}
                            icon={<Image src={icon} height={4} />}
                            onClick={pop}
                        />
                        <ToolbarButton
                            label={"Share board"}
                            icon={<FaShareAlt />}
                            onClick={onShareBoardClick}
                        />
                    </HStack>
                </Box>

                <TextEntryCreateModal
                    isOpen={isTextModalOpen}
                    onClose={onTextModalClose}
                    onSubmit={(t: string) =>
                        addEntry({ type: "text", payload: t })
                    }
                />

                <SimpleTextEntryCreateModal
                    isOpen={isStickyModalOpen}
                    onClose={onStickyModalClose}
                    onSubmit={(t: string) =>
                        addEntry({ type: "sticky", payload: t })
                    }
                />

                <ShareCodeModal
                    isOpen={isShareModalOpen}
                    onClose={onShareModalClose}
                />
            </div>
        </>
    );
};
