import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useStorage } from "../hooks/useStorage";

interface BoardContextType {
    shareCode: string;
    setShareCode: (shareCode: string) => void;
    isReadOnly: boolean;
    setIsReadOnly: (isReadOnly: boolean) => void;
}

export const BoardContext = createContext<BoardContextType | undefined>(
    undefined
);

export const BoardProvider = ({ children }: PropsWithChildren) => {
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [shareCode, setShareCode] = useStorage<string>(
        "shareCode",
        "",
        isReadOnly
    );

    return (
        <BoardContext.Provider
            value={{ shareCode, setShareCode, isReadOnly, setIsReadOnly }}
        >
            {children}
        </BoardContext.Provider>
    );
};

export const useBoard = (): BoardContextType => {
    const context = useContext(BoardContext);
    if (!context) {
        throw new Error("useBoard must be used within a BoardProvider");
    }

    return context;
};
