import { EntryType } from "perf_hooks";

export function isEntryType(value: string): value is EntryType {
    return (
        value === "text" ||
        value === "code" ||
        value === "web" ||
        value === "sticky" ||
        value === "youtube" ||
        value === "image" ||
        value === "audio" ||
        value === "pdf" ||
        value === "video"
    );
}
