import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DreamBoard } from "../app/DreamBoard";
import { useAuth } from "../contexts/AuthContext";
import { useBoard } from "../contexts/BoardContext";
import { getShareFromFirebase } from "../hooks/useStorage";
import { BottomToolbar } from "../toolbars/BottomToolbar";

export const ReadOnlyDreamBoard = () => {
    const [scale, setScale] = useState(0.8);

    const { setIsReadOnly } = useBoard();
    const { setUserId } = useAuth();
    const { id } = useParams();

    useEffect(() => {
        if (!id) return;

        const fetchData = async () => {
            const userId = await getShareFromFirebase<string>(id);
            if (userId) setUserId(userId);
        };

        fetchData();
    }, [id, setUserId]);

    useEffect(() => {
        const viewportHeight = window.innerHeight;
        const elementHeight = 1300;
        const initialScale = viewportHeight / elementHeight;

        setScale(initialScale);
        setIsReadOnly(true);
    }, [setIsReadOnly]);

    return (
        <>
            <DreamBoard scale={scale} />
            <BottomToolbar defaultValue={scale} onChange={(v) => setScale(v)} />
        </>
    );
};
