import { get, getDatabase, ref, set } from "firebase/database";
import { openDB } from "idb";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";

const dbPromise = openDB("dreamboard", 1, {
    upgrade(db) {
        db.createObjectStore("appState");
    },
});

const getFromIndexedDB = async <T>(
    key: string,
    initialValue: T
): Promise<T> => {
    const db = await dbPromise;
    const value = await db.get("appState", key);

    return value !== undefined ? value : initialValue;
};

const setToIndexedDB = async <T>(key: string, value: T): Promise<void> => {
    const db = await dbPromise;

    await db.put("appState", value, key);
};

export const getShareFromFirebase = async <T>(shareId: string): Promise<T> => {
    const db = getDatabase();
    const dbRef = ref(db, `shares/${shareId}`);
    const snapshot = await get(dbRef);

    return snapshot.exists() ? snapshot.val() : "";
};

const getFromFirebase = async <T>(
    userId: string,
    key: string,
    initialValue: T
): Promise<T> => {
    const db = getDatabase();
    const dbRef = ref(db, `users/${userId}/${key}`);
    const snapshot = await get(dbRef);

    return snapshot.exists() ? snapshot.val() : initialValue;
};

const setToFirebase = async <T>(
    userId: string,
    key: string,
    value: T
): Promise<void> => {
    const db = getDatabase();
    const dbRef = ref(db, `users/${userId}/${key}`);

    await set(dbRef, value);
};

export function useStorage<T>(
    key: string,
    initialValue: T,
    isReadonly?: boolean | undefined
): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [state, setState] = useState(initialValue);
    const [isMounted, setIsMounted] = useState(false);

    const { userId } = useAuth();

    useEffect(() => {
        const loadState = async () => {
            if (!userId) return;

            const storedValue = navigator.onLine
                ? await getFromFirebase(userId, key, initialValue)
                : await getFromIndexedDB(key, initialValue);

            setIsMounted(true);
            setState(storedValue);
        };

        loadState();
    }, [key, userId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isReadonly) return;

        if (userId && isMounted) {
            setToFirebase(userId, key, state);
        }

        setToIndexedDB(key, state);
    }, [key, state, userId, isMounted, isReadonly]);

    return [state, setState];
}
