import { Link } from "@chakra-ui/react";
import { AnyEntry } from "@dreamboard/AnyEntry";
import { useEntries } from "../contexts/EntryContext";
import "./CloseLink.css"; // Ensure you import the CSS file
import { ReactComponent as CloseIcon } from "../img/SVG/Close.svg";

export interface CloseLinkProps {
    entry: AnyEntry;
    colour: string;
}

export const CloseLink = ({ entry, colour }: CloseLinkProps) => {
    const { deleteEntry } = useEntries();

    return (
        <Link onClick={() => deleteEntry(entry)}>
            <CloseIcon width="10px" color={colour} className="close-icon" />
        </Link>
    );
};
