import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
} from "@chakra-ui/react";
import { TextEntry } from "@dreamboard/TextEntry";
import { useState } from "react";
import { TextEditor } from "../components/TextEditor";

export interface TextEntryUpdateModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (updatedEntry: TextEntry) => void;
    entry: TextEntry;
}

export const TextEntryUpdateModal = ({
    isOpen,
    onClose,
    onSubmit,
    entry,
}: TextEntryUpdateModalProps) => {
    const [text, setText] = useState(entry.payload);

    const handleModalClose = () => {
        onSubmit({ ...entry, payload: text });
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />

                <ModalBody>
                    <TextEditor
                        initialContent={text}
                        onChange={(content) => setText(content)}
                    />
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleModalClose}>
                        Save
                    </Button>

                    <Button variant="ghost" ml={3} onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
