import { Button, IconButton } from "@chakra-ui/react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useState } from "react";
import {
    FaAlignCenter,
    FaAlignLeft,
    FaAlignRight,
    FaBold,
    FaItalic,
    FaStrikethrough,
} from "react-icons/fa";

import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import FontSize from "tiptap-extension-font-size";

import "./TextEditor.css";

const extensions = [
    StarterKit,
    TextStyle,
    FontSize,
    TextAlign.configure({
        types: ["heading", "paragraph"],
    }),
];

export interface TextEditorProps {
    initialContent?: string;
    onChange: (content: string) => void;
}

export const TextEditor = ({ initialContent, onChange }: TextEditorProps) => {
    const [isLeft, setIsLeft] = useState(false);
    const [isCenter, setIsCenter] = useState(false);
    const [isRight, setIsRight] = useState(false);
    const [isSmall, setIsSmall] = useState(false);
    const [isMedium, setIsMedium] = useState(true);
    const [isLarge, setIsLarge] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);

    const editor = useEditor({
        extensions,
        content: initialContent,
        autofocus: "end",
        onUpdate({ editor }) {
            setIsBold(editor.isActive("bold"));
            setIsItalic(editor.isActive("italic"));
            setIsStrikethrough(editor.isActive("strikethrough"));
            onChange(editor.getHTML());
        },
    });

    useEffect(() => {
        if (editor && editor.isEditable) {
            editor.commands.focus("end");
        }
    }, [editor]);

    return (
        <>
            <IconButton
                mb={3}
                size="sm"
                aria-label="Bold"
                isActive={isBold}
                onClick={() => {
                    setIsBold(!isBold);

                    return editor!.chain().focus().toggleBold().run();
                }}
            >
                <FaBold />
            </IconButton>

            <IconButton
                mb={3}
                ml={0.5}
                size="sm"
                aria-label="Italic"
                isActive={isItalic}
                onClick={() => {
                    setIsItalic(!isItalic);

                    return editor!.chain().focus().toggleItalic().run();
                }}
            >
                <FaItalic />
            </IconButton>

            <IconButton
                mb={3}
                ml={0.5}
                size="sm"
                aria-label="Strikethrough"
                isActive={isStrikethrough}
                onClick={() => {
                    setIsStrikethrough(!isStrikethrough);

                    return editor!.chain().focus().toggleStrike().run();
                }}
            >
                <FaStrikethrough />
            </IconButton>

            <Button
                mb={3}
                ml={2}
                size="sm"
                aria-label="Small font"
                isActive={isSmall}
                onClick={() => {
                    setIsSmall(!isSmall);
                    setIsMedium(false);
                    setIsLarge(false);

                    return editor!.chain().focus().setFontSize("12px").run();
                }}
            >
                S
            </Button>

            <Button
                mb={3}
                ml={0.5}
                size="sm"
                aria-label="Medium font"
                isActive={isMedium}
                onClick={() => {
                    setIsSmall(false);
                    setIsMedium(!isMedium);
                    setIsLarge(false);

                    return editor!.chain().focus().setFontSize("16px").run();
                }}
            >
                M
            </Button>

            <Button
                mb={3}
                ml={0.5}
                size="sm"
                aria-label="Large font"
                isActive={isLarge}
                onClick={() => {
                    setIsSmall(false);
                    setIsMedium(false);
                    setIsLarge(!isLarge);

                    return editor!.chain().focus().setFontSize("24px").run();
                }}
            >
                L
            </Button>

            <Button
                mb={3}
                ml={2}
                size="sm"
                aria-label="Left align"
                isActive={isLeft}
                onClick={() => {
                    setIsLeft(true);
                    setIsCenter(false);
                    setIsRight(false);

                    return editor!.chain().focus().setTextAlign("left").run();
                }}
            >
                <FaAlignLeft />
            </Button>

            <Button
                mb={3}
                ml={0.5}
                size="sm"
                aria-label="Center align"
                isActive={isCenter}
                onClick={() => {
                    setIsLeft(false);
                    setIsCenter(true);
                    setIsRight(false);

                    return editor!.chain().focus().setTextAlign("center").run();
                }}
            >
                <FaAlignCenter />
            </Button>

            <Button
                mb={3}
                ml={0.5}
                size="sm"
                aria-label="Right align"
                isActive={isRight}
                onClick={() => {
                    setIsLeft(false);
                    setIsCenter(false);
                    setIsRight(true);

                    return editor!.chain().focus().setTextAlign("right").run();
                }}
            >
                <FaAlignRight />
            </Button>

            <EditorContent
                className="TextEditor"
                autoFocus={true}
                editor={editor}
                rows={10}
                height={10}
            />
        </>
    );
};
