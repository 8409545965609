import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { createContext, PropsWithChildren, useContext, useState } from "react";

interface AuthContextType {
    isLoading: boolean;
    isLoggedIn: boolean;
    user?: User | null;
    userId?: string | null;
    setUserId: (userId: string) => void;
    setUser: (user: User) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(
    undefined
);

export const AuthProvider = ({ children }: PropsWithChildren) => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<User | undefined>();
    const [userId, setUserId] = useState("");

    const auth = getAuth();

    setTimeout(() => setIsLoading(false), 1000);

    onAuthStateChanged(auth, (user) => {
        setTimeout(() => setIsLoading(false), 1000);

        if (user) {
            setUserId(user.uid);
            setUser(user);
        }
    });

    return (
        <AuthContext.Provider
            value={{
                isLoading,
                isLoggedIn: !!user,
                user,
                setUser,
                userId,
                setUserId,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return context;
};
