import { VideoEntry } from "@dreamboard/VideoEntry";
import { useEntries } from "../contexts/EntryContext";
import { Thing } from "./Thing";

import "./Thing.css";

export interface VideoThingProps {
    entry: VideoEntry;
}

export const VideoThing = ({ entry }: VideoThingProps) => {
    const { editEntry, deleteEntry } = useEntries();

    return (
        <Thing
            dataTestId="video-thing"
            entry={entry}
            onUpdate={editEntry}
            onDelete={deleteEntry}
        >
            <video controls style={{ width: "100%" }}>
                <source src={entry.payload} />
                Your browser does not support the video tag.
            </video>
        </Thing>
    );
};
