import { useToast } from "@chakra-ui/react";
import { AnyEntry } from "@dreamboard/AnyEntry";
import { NewEntry } from "@dreamboard/NewEntry";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { PropsWithChildren, createContext, useContext } from "react";
import { v4 } from "uuid";
import { useStorage } from "../hooks/useStorage";
import { useBoard } from "./BoardContext";
import { useUndo } from "./UndoContext";

interface EntryContextType {
    entries: AnyEntry[];
    addEntry: (entry: NewEntry) => void;
    editEntry: (entry: AnyEntry) => void;
    deleteEntry: (entry: AnyEntry) => void;
}

export const EntryContext = createContext<EntryContextType | undefined>(
    undefined
);

export const EntryProvider = ({ children }: PropsWithChildren) => {
    const { isReadOnly } = useBoard();

    const [entries, setEntries] = useStorage<AnyEntry[]>(
        "entries",
        [],
        isReadOnly
    );

    const { push } = useUndo();
    const toast = useToast();

    const addEntry = ({ payload, type }: NewEntry) => {
        if (entries.length === 10) {
            toast({
                title: "Whoops!",
                description:
                    "You've reached the max number of things for this board, sorry!",
                status: "error",
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        const oldEntries = [...entries.map((t) => ({ ...t }))];
        push(() => setEntries(oldEntries));

        setEntries([
            ...entries,
            {
                payload,
                type,
                id: v4(),
                x: 0,
                y: 0,
                colour: "white",
                isSelected: false,
            },
        ]);
    };

    const editEntry = (updatedEntry: AnyEntry) => {
        const oldEntries = [...entries.map((t) => ({ ...t }))];
        push(() => setEntries(oldEntries));

        setEntries((prevEntries) =>
            prevEntries.map((entry) =>
                entry.id === updatedEntry.id ? updatedEntry : entry
            )
        );
    };

    const deleteEntry = (entryToDelete: AnyEntry) => {
        const oldEntries = [...entries.map((t) => ({ ...t }))];
        push(() => setEntries(oldEntries));

        setEntries((prevEntries) =>
            prevEntries.filter((entry) => entry.id !== entryToDelete.id)
        );

        try {
            const storage = getStorage();
            const { payload } = entryToDelete;
            const filePath = payload
                .split("/o/")[1]
                .split("?")[0]
                .replace(/%2F/g, "/");

            const fileRef = ref(storage, filePath);

            deleteObject(fileRef);
        } catch (_) {}
    };

    return (
        <EntryContext.Provider
            value={{
                entries: entries,
                addEntry: addEntry,
                editEntry: editEntry,
                deleteEntry: deleteEntry,
            }}
        >
            {children}
        </EntryContext.Provider>
    );
};

export const useEntries = (): EntryContextType => {
    const context = useContext(EntryContext);
    if (!context) {
        throw new Error("useEntries must be used within an EntryProvider");
    }

    return context;
};
