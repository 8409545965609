import {
    Box,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
} from "@chakra-ui/react";

import "./BottomToolbar.css";

export interface BottomToolbarProps {
    defaultValue: number;
    onChange: (value: number) => void;
}

export const BottomToolbar = ({
    defaultValue,
    onChange,
}: BottomToolbarProps) => {
    return (
        <div className="BottomToolbar">
            <Box
                p={2}
                pl={4}
                pr={4}
                borderWidth="0px"
                borderRadius="3xl"
                backgroundColor="#FFFFFF"
                boxShadow="0px 3px 6px rgba(0, 0, 0, 0.1)" // Hard shadow for the outer box
            >
                <Slider
                    aria-label="zoom-slider"
                    defaultValue={defaultValue}
                    min={0.5}
                    max={2}
                    step={0.01}
                    onChange={(value: number) => onChange(value)}
                    width={260}
                >
                    <SliderTrack bg="#D3CAE0"> 
                        <SliderFilledTrack bg="#130B1E" />
                    </SliderTrack>
                    <SliderThumb boxShadow="0px 0px 2px 3px rgba(0, 0, 0, 0.1)" /> 
                </Slider>
            </Box>
        </div>
    );
};
