import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";
import { TextEditor } from "../components/TextEditor";

export interface TextEntryCreateModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (value: string) => void;
}

export const TextEntryCreateModal = ({
    isOpen,
    onClose,
    onSubmit,
}: TextEntryCreateModalProps) => {
    const [text, setText] = useState("");

    const handleModalClose = () => {
        onSubmit(text);
        setText("");
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />

                <ModalBody>
                    <TextEditor onChange={(content) => setText(content)} />
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleModalClose}>
                        Save
                    </Button>

                    <Button variant="ghost" ml={3} onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
