import { AudioEntry } from "@dreamboard/AudioEntry";
import { useEntries } from "../contexts/EntryContext";
import { Thing } from "./Thing";

import "./Thing.css";

export interface AudioThingProps {
    entry: AudioEntry;
}

export const AudioThing = ({ entry }: AudioThingProps) => {
    const { editEntry, deleteEntry } = useEntries();

    return (
        <Thing
            dataTestId="audio-thing"
            entry={entry}
            onUpdate={editEntry}
            onDelete={deleteEntry}
        >
            <audio controls style={{ width: "100%" }}>
                <source src={entry.payload} />
                Your browser does not support the audio tag.
            </audio>
        </Thing>
    );
};
