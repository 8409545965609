import { createContext, PropsWithChildren, useContext } from "react";
import { useStorage } from "../hooks/useStorage";
import { useBoard } from "./BoardContext";

interface ColourChoice {
    colour: string;
    timestamp: number;
}

interface ColourContextType {
    colour: ColourChoice;
    setColour: (colour: string) => void;
    showPalette: boolean;
    setShowPalette: (showPalette: boolean) => void;
}

export const ColourContext = createContext<ColourContextType | undefined>(
    undefined
);

export const ColourProvider = ({ children }: PropsWithChildren) => {
    const { isReadOnly } = useBoard();

    const [showPalette, setShowPalette] = useStorage<boolean>(
        "showPalette",
        false,
        isReadOnly
    );

    const [colour, setColourState] = useStorage<ColourChoice>(
        "colour",
        {
            colour: "white",
            timestamp: Date.now(),
        },
        isReadOnly
    );

    const setColour = (colour: string) =>
        setColourState({ colour, timestamp: Date.now() });

    return (
        <ColourContext.Provider
            value={{ colour, setColour, showPalette, setShowPalette }}
        >
            {children}
        </ColourContext.Provider>
    );
};

export const useColour = (): ColourContextType => {
    const context = useContext(ColourContext);
    if (!context) {
        throw new Error("useColour must be used within a ColourProvider");
    }

    return context;
};
