import { useEntries } from "../contexts/EntryContext";
import { Thing } from "./Thing";

import { TextEntry } from "@dreamboard/TextEntry";
import "highlight.js/styles/github.css";
import Highlight from "react-highlight.js";

import "./Thing.css";

export interface CodeThingProps {
    entry: TextEntry;
}

export const CodeThing = ({ entry }: CodeThingProps) => {
    const { editEntry, deleteEntry } = useEntries();

    return (
        <>
            <Thing
                entry={entry}
                dataTestId="text-thing"
                onUpdate={editEntry}
                onDelete={deleteEntry}
            >
                <Highlight
                    style={{
                        marginBottom: "12px",
                        width: "100%",
                        fontSize: 10,
                    }}
                    language="javascript"
                >
                    {entry.payload}
                </Highlight>
            </Thing>
        </>
    );
};
