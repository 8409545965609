import { AnyEntry } from "@dreamboard/AnyEntry";
import { Dropzone } from "../components/Dropzone";
import { useEntries } from "../contexts/EntryContext";
import { ImageThing } from "../things/ImageThing";
import { TextThing } from "../things/TextThing";

import { EntryType } from "@dreamboard/EntryType";
import { ReactElement } from "react";
import { AudioThing } from "../things/AudioThing";
import { CodeThing } from "../things/CodeThing";
import { PdfThing } from "../things/PdfThing";
import { StickyThing } from "../things/StickyThing";
import { VideoThing } from "../things/VideoThing";
import { WebThing } from "../things/WebThing";
import { YouTubeThing } from "../things/YouTubeThing";
import "./DreamBoard.css";

export interface DreamBoardProps {
    scale: number;
}

export const DreamBoard = ({ scale }: DreamBoardProps) => {
    const { entries } = useEntries();

    return (
        <div
            data-testid="dreamboard"
            className={`DreamBoard`}
            style={{ transform: `scale(${scale})` }}
        >
            <div className="printable">
                <Dropzone />

                {entries.map(getThing)}
            </div>
        </div>
    );
};

function getThing(entry: AnyEntry) {
    const thingFactories: Record<EntryType, () => ReactElement> = {
        pdf: () => <PdfThing key={entry.id} entry={entry} />,
        text: () => <TextThing key={entry.id} entry={entry} />,
        code: () => <CodeThing key={entry.id} entry={entry} />,
        web: () => <WebThing key={entry.id} entry={entry} />,
        image: () => <ImageThing key={entry.id} entry={entry} />,
        audio: () => <AudioThing key={entry.id} entry={entry} />,
        video: () => <VideoThing key={entry.id} entry={entry} />,
        sticky: () => <StickyThing key={entry.id} entry={entry} />,
        youtube: () => <YouTubeThing key={entry.id} entry={entry} />,
    };

    return thingFactories[entry.type]();
}
