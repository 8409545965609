import { initializeApp } from "firebase/app";
import ReactDOM from "react-dom/client";

import { App } from "./app/App";
import { firebaseConfig } from "./firebaseConfig";
import { reportWebVitals } from "./reportWebVitals";

import { AppProvider } from "./helpers/AppProvider";

import "./index.css";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

initializeApp(firebaseConfig);

root.render(
    <AppProvider>
        <App />
    </AppProvider>
);

reportWebVitals();
