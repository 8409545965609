import { useToast } from "@chakra-ui/react";
import { AnyEntry } from "@dreamboard/AnyEntry";
import { EntryType } from "@dreamboard/EntryType";
import { WebMetadata } from "@dreamboard/WebMetadata";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { PropsWithChildren, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { useAuth } from "../contexts/AuthContext";
import { useEntries } from "../contexts/EntryContext";

import { isEntryType } from "../helpers/isEntryType";
import "./Dropzone.css";

const apiUrl = process.env.REACT_APP_API_URL;

export const Dropzone = ({ children }: PropsWithChildren) => {
    const [isLoading, setIsLoading] = useState(false);

    const { user, userId } = useAuth();
    const { entries, addEntry, editEntry } = useEntries();
    const toast = useToast();

    const { getRootProps, isDragActive } = useDropzone({
        onDrop: (files) => files.forEach((f) => handleFile(f)),
    });

    const handleFile = async (file: Blob) => {
        if (!user) return;

        if (file.size > 50 * 1024 * 1024) {
            toast({
                title: "File size too large!",
                description: "The file size exceeds the 50 MB limit.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        const storage = getStorage();
        const fileName = `file_${Date.now()}`;
        const fileRef = ref(storage, `${userId}/${fileName}`);
        const type = file.type
            .replaceAll("application/", "")
            .split("/")[0] as EntryType;

        if (!isEntryType(type)) {
            toast({
                title: "Adding file failed!",
                description: `Dreamboard currently doesn't support those files, sorry!`,
                status: "error",
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        try {
            await uploadBytes(fileRef, file);
            const payload = await getDownloadURL(fileRef);

            addEntry({ type, payload });
        } catch (error) {
            toast({
                title: "Error synchronising files!",
                description: "Something bad happened, try again later?",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const deselectAllEntries = () => {
        entries.forEach((e: AnyEntry) =>
            editEntry({ ...e, isSelected: false })
        );
    };

    useEffect(() => {
        const handlePaste = async (event: ClipboardEvent) => {
            event.preventDefault();
            setIsLoading(true);

            try {
                const clipboardData = event.clipboardData;

                if (clipboardData) {
                    for (
                        let index = 0;
                        index < clipboardData.items.length;
                        index++
                    ) {
                        const item = clipboardData.items[index];
                        const type = item.type.split("/")[0];

                        if (
                            ["image", "audio", "video"].includes(type) ||
                            item.type === "application/pdf"
                        ) {
                            const blob = item.getAsFile();
                            if (blob) await handleFile(blob);
                        }

                        if (item.type.includes("text")) {
                            const payload = clipboardData.getData("text");

                            if (
                                !/^https?:\/\/[^\s$.?#].[^\s]*$/.test(payload)
                            ) {
                                const looksLikeCode =
                                    /(?:function|var|let|const|if|else|return|class|import|export|console\.log)/.test(
                                        payload
                                    );
                                addEntry({
                                    type: looksLikeCode ? "code" : "text",
                                    payload,
                                });
                                return;
                            }

                            const response = await fetch(
                                `${apiUrl}/?url=${payload}`
                            );
                            const webMetadata =
                                (await response.json()) as WebMetadata;

                            if (webMetadata.type === "web") {
                                addEntry({ type: "web", payload: webMetadata });
                            } else {
                                addEntry({
                                    type: webMetadata.type as EntryType,
                                    payload: webMetadata.url,
                                });
                            }
                        }
                    }
                }
            } catch (error) {
                toast({
                    title: "Whoops!",
                    description:
                        "Something gnarly happened trying to load that link. :-(",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        document.addEventListener("paste", handlePaste);

        return () => {
            document.removeEventListener("paste", handlePaste);
        };
    }, [addEntry, toast, apiUrl]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        (isLoading && <LoadingSpinner />) || (
            <div
                {...getRootProps()}
                className={`Dropzone ${isDragActive ? "active" : ""}`}
                onClick={deselectAllEntries}
                data-testid="dropzone"
            >
                {children}
            </div>
        )
    );
};
