import { Link } from "@chakra-ui/react";
import { ReactComponent as ExternalIcon } from "../img/SVG/External.svg";

export interface ExternalLinkProps {
    url: string;
    colour: string;
}

export const ExternalLink = ({ url, colour }: ExternalLinkProps) => {
    return (
        <Link target="_blank" rel="noreferrer" href={url} isExternal>
            <ExternalIcon width="12px" color={colour} />
        </Link>
    );
};
