import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    useToast,
    VStack,
    Image,
} from "@chakra-ui/react";
import {
    Auth,
    AuthProvider,
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";

import { useState } from "react";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { useAuth } from "../contexts/AuthContext";
import { LoadingSpinner } from "./LoadingSpinner";
import LogoLogin from '../img/SVG/Logo_Login.svg'; 
import '../components/BackgroundAnimation.css'; 

export const SignInForm = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { setUser } = useAuth();
    const toast = useToast();

    function errorToast(description?: string) {
        toast({
            title: "Error logging in!",
            description:
                description ?? "Something bad happened, try again later?",
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    }

    function socialSignIn(auth: Auth, provider: AuthProvider) {
        signInWithPopup(auth, provider)
            .then((result) => {
                setUser(result.user);
            })
            .catch((e) => {
                const description = e.message.includes("account-exists")
                    ? "Looks like you already have a Dreamboard account!"
                    : "Something bad happened, try again later?";

                errorToast(description);
            })
            .finally(() => setIsLoading(false));
    }

    function signIn(auth: Auth) {
        signInWithEmailAndPassword(auth, email, password)
            .then((result) => {
                setUser(result.user);
            })
            .catch(errorToast)
            .finally(() => setIsLoading(false));
    }

    function signUp(auth: Auth) {
        createUserWithEmailAndPassword(auth, email, password)
            .then((result) => {
                setUser(result.user);
            })
            .catch(errorToast);
    }

    return isLoading ? (
        <LoadingSpinner label="Logging in..." />
    ) : (
        <Container centerContent>
            <Box
                mt={8} 
                p={8}
                pt={0}
                borderWidth={1}
                borderRadius="lg"
                boxShadow="lg"
                width="100%"
                maxWidth="md"
                bg={"#f0f0f0"} 
            >
                <VStack spacing={0} alignItems="center">
                    <Image src={LogoLogin} alt="LogoLogin" boxSize="120px" />
                    <Heading 
                        size="lg" 
                        mt={0}   
                        pt={0} 
                        
                    >
                        Sign In
                    </Heading>
                </VStack>

                <form>
                    <Stack spacing={4}>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel>Password</FormLabel>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormControl>

                        <ButtonGroup spacing={4} width="full">
                            <Button
                                bgColor="#3EB73E" // Custom button color
                                color="#FFFFFF" // Button text color
                                _hover={{ bg: "#45a049" }} // Hover state color
                                type="submit"
                                width="full"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsLoading(true);
                                    signIn(getAuth());
                                }}
                            >
                                Sign In
                            </Button>

                            <Button
                                bgColor="#1EAAF7" // Custom button color
                                color="white" // Button text color
                                _hover={{ bg: "#007bb5" }} // Hover state color
                                type="submit"
                                width="full"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsLoading(true);
                                    signUp(getAuth());
                                }}
                            >
                                Sign Up
                            </Button>
                        </ButtonGroup>
                    </Stack>
                </form>

                <Divider my={6} />

                <Stack spacing={4}>
                    <Button
                        leftIcon={<FaGoogle />}
                        bgColor="#DB4437" // Custom button color for Google
                        color="white" // Button text color
                        _hover={{ bg: "#c23321" }} // Hover state color
                        width="full"
                        onClick={() => {
                            setIsLoading(true);
                            socialSignIn(getAuth(), new GoogleAuthProvider());
                        }}
                    >
                        Sign In with Google
                    </Button>

                    <Button
                        leftIcon={<FaFacebook />}
                        bgColor="#3B5998" // Custom button color for Facebook
                        color="white" // Button text color
                        _hover={{ bg: "#30487b" }} // Hover state color
                        width="full"
                        onClick={() => {
                            setIsLoading(true);
                            socialSignIn(getAuth(), new FacebookAuthProvider());
                        }}
                    >
                        Sign In with Facebook
                    </Button>
                </Stack>
            </Box>
        </Container>
    );
};
